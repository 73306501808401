import styled from "styled-components"

export const Wrap = styled.div`
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding-top: 80px;
  position: relative;
  z-index: 2;
`

export const Title = styled.h1`
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 45px;
`

export const Button = styled.a`
  border: 2px solid white;
  padding: 15px 25px;
  font-size: 14px;
  text-transform: uppercase;
  align-self: center;
`

export const Gallery = styled.div`
  list-style: none;
  position: relative;
  z-index: 1;
`

export const Image = styled.li<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 400px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 64px);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: all 1.5s;
`

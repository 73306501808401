import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import Header from "../../components/header/Header"
import { graphql, Link } from "gatsby"
import { Title, Wrap, Gallery, Image } from "./Home.css"
import MainWrap from "../../components/main-wrap/MainWrap"
import { Lang, PageContext } from "../../constants"

export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions

const Home: FunctionComponent<AllProps> = ({ data, pageContext }) => {
  const { image, gallery } = data.allMarkdownRemark.nodes[0].frontmatter
  const [activeImage, setActiveImage] = useState(0)
  const getValue = param => {
    const query = data.allMarkdownRemark.nodes[0].frontmatter
    try {
      return pageContext.lang === Lang.LV ? query[param] : query[`${param}_en`]
    } catch (e) {
      console.warn(e)
      return ""
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage(prevState =>
        prevState >= gallery.length - 1 ? 0 : prevState + 1
      )
    }, 4000)

    return () => {
      window.clearInterval(Number(interval))
    }
  }, [])

  const hasGallery = Boolean(gallery && gallery.length >= 2)
  return (
    <MainWrap>
      <Header lang={pageContext.lang} title={"Delisnack restorāni"} />

      {hasGallery && (
        <Gallery>
          {gallery.map(({ url }, index) => (
            <Image
              key={url}
              style={{ backgroundImage: `url(${url})` }}
              isActive={activeImage === index}
            />
          ))}
        </Gallery>
      )}

      <Wrap style={{ backgroundImage: hasGallery ? "none" : `url(${image})` }}>
        <Title>
          {getValue("title")}
          <br />
          {getValue("subTitle")}
        </Title>
        <div style={{
            alignSelf: "center"
        }}>
        <Link
          to={`${
            pageContext.lang === Lang.LV ? "/menu" : `/${pageContext.lang}/menu`
          }`}
          style={{
            border: "2px solid white",
            padding: "15px 25px",
            fontSize: "14px",
            textTransform: "uppercase",
            marginRight: "15px",
          }}
        >
          {getValue("button_title")}
        </Link>
        <Link
          to={`${
            pageContext.lang === Lang.LV ? "/drinks" : `/${pageContext.lang}/drinks`
          }`}
          style={{
            border: "2px solid white",
            padding: "15px 25px",
            fontSize: "14px",
            textTransform: "uppercase",
            alignSelf: "center",
          }}
        >
          {getValue("button2_title")}
        </Link>
        </div>
      </Wrap>
    </MainWrap>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { fields: { collection: { eq: "home" } } }) {
      nodes {
        frontmatter {
          button_title
          button_title_en
          button2_title
          button2_title_en
          title
          title_en
          subTitle
          subTitle_en
          image
          logo
          gallery {
            url
          }
        }
      }
    }
  }
`

export default Home
